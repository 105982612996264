import { base_url, history } from "../../../base"
import Swal from "sweetalert2";
import axios from "axios";

export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_LOADING = "LOGIN_LOADING";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const SIGN_OUT = "SIGN_OUT";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";

// axios.defaults.withCredentials = true;

export function signupActions(userData) {

    return dispatch => {
        const data = {
            name: userData.name,
            email: userData.email,
            password: userData.password,
            agree: true,
            path: window.location.origin
        };
        axios
            .post(`${base_url()}/api/v1/register`, data)
            .then((token) => {
                if (token.data.success) {
                    history.push(`/otp_emailSent/${data.email}`)
                    dispatch({
                        type: SIGNUP_SUCCESS,
                        token: token.data.token,
                    });
                } else {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: token.data.message,
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            })
            .catch((error) => {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: error,
                    showConfirmButton: false,
                    timer: 2000
                })
            });
    }
}

export function loginWithEmailAndPassword(userData, setShow) {
    const { email, password, subscription_id, configID, login_otp } = userData
    return (dispatch) => {
        return new Promise(resolve => {
            setTimeout(() => {
                dispatch({ type: LOGIN_LOADING });
                resolve(
                    axios
                        .post(`${base_url()}/api/v1/signin`, { email, password, subscription_id, configID, login_otp }, { withCredentials: true, credentials: 'include' })
                        .then((response) => {
                            const { result, messageNotify, planMenu, messageNav, messageMain, messageDay, userTe, navigationList, permissionList } = response.data || {}
                            if (response.data.status) {
                                window.location.href = "/board"
                                if (messageNotify.length > 0) {
                                    return dispatch({
                                        type: LOGIN_SUCCESS,
                                        data: {
                                            datas: result, // admin dashboard
                                            dataType: messageNav, // user dashboard
                                            dataTypeMain: messageMain, // user dashboard
                                            dataTypePerm: [],  // user dashboard
                                            userDay: messageDay,  // user day
                                            userTeam: userTe,  // user team
                                        },
                                    });
                                } else {
                                    if (messageNav.length > 0) {
                                        return dispatch({
                                            type: LOGIN_SUCCESS,
                                            data: {
                                                datas: result, // admin dashboard
                                                dataType: messageNav,  // user dashboard
                                                dataTypeMain: messageMain,
                                                userDay: messageDay,  // user day
                                                userTeam: userTe,  // user team
                                            },
                                        });
                                    } else {
                                        return dispatch({
                                            type: LOGIN_SUCCESS,
                                            data: {
                                                datas: result, // admin dashboard
                                                userDay: [],  // user day
                                                navigation: navigationList, // list navigation
                                                permission: permissionList, // list permission
                                                planMenu,
                                            },
                                        });
                                    }
                                }
                            } else {
                                if (response.data?.redirect) {
                                    history.push({ pathname: "/otp_sent", state: { email, password, subscription_id, configID } })
                                } else if (response.data?.modal_open) {
                                    setShow(true);
                                } else {
                                    Swal.fire({
                                        position: 'center',
                                        icon: 'error',
                                        title: response.data.message,
                                        showConfirmButton: false,
                                        timer: 2000
                                    })
                                }
                            }
                        })
                        .catch((error) => {
                            history.push({ pathname: "/signin" });
                            Swal.fire({
                                position: 'center',
                                icon: 'error',
                                // title: "Please try to login with correct credentials!",
                                title: error.response?.data?.message || "Please try to login with correct credentials!",
                                showConfirmButton: false,
                                timer: 2000
                            })
                        })
                )
            }, 1000)
        })
    }
}

export const signOut = (id) => {
    return dispatch => {
        fetch(`${base_url()}/api/v1/authlogout/${id}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true, credentials: 'include'
        })
            .then(response => response.json())
            .then(data => {
                if (data.status) {
                    dispatch({ type: SIGN_OUT });
                    localStorage.removeItem("persist:root")
                    history.push({ pathname: "/logout" });
                    history.push({ pathname: "/signin" });
                } else {
                    history.push({ pathname: "/dashboard" });
                }
            })
            .catch((error) => {
                console.log(error, 'error')
                history.push({ pathname: "/" });
            });
    };
}

export const updateProfileImage = (name, value) => {
    return (dispatch, getState) => {
        return new Promise(resolve => {
            setTimeout(() => {
                dispatch({ type: LOGIN_LOADING });
                const { login } = getState();
                resolve(dispatch({
                    type: LOGIN_SUCCESS,
                    data: {
                        ...login.payload.data,
                        datas: {
                            ...login.payload.data.datas,
                            [name]: value
                        }
                    }
                }));
            }, 500)
        })
    }
}