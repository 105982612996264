const ReadExcelFile = async (data, targetColumns, setItems, setUnmatchedColumns, setIsModalOpen, setShowImport) => {
    const transformedData = data.map((row) => {
        const mappedRow = {};
        targetColumns.forEach((targetColumn) => {
            const matchingColumn = Object.keys(row).find(
                (column) => column.toLowerCase().includes(targetColumn.toLowerCase())
            );
            if (matchingColumn) {
                mappedRow[targetColumn] = row[matchingColumn];
            }
        });
        return mappedRow;
    });
    console.log({transformedData})
    setItems(transformedData);

    const csvColumns = Object.keys(data[0]);

    const matchedColumns = Array.isArray(targetColumns) && targetColumns.map(targetColumn => {
        const matchingColumn = csvColumns.find(csvColumn =>
            csvColumn.toLowerCase().includes(targetColumn.toLowerCase())
        );
        return matchingColumn || '';
    });

    const unmatchedColumns = csvColumns.filter((csvColumn) => !matchedColumns.includes(csvColumn));

    if (unmatchedColumns.length > 0) {
        setIsModalOpen(true);
        setShowImport(false);
    }

    let unmatchedIndex = 0;
    const updatedMatchedColumns = matchedColumns.map(column => column === '' ? (unmatchedColumns[unmatchedIndex++] || '') : column);
    while (unmatchedIndex < unmatchedColumns.length) {
        updatedMatchedColumns.push(unmatchedColumns[unmatchedIndex++]);
    }

    setUnmatchedColumns(updatedMatchedColumns);
}

export default ReadExcelFile;